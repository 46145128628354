// Core
@import 'custom/variables';
@import '~node_modules/bootstrap/scss/variables';
@import '~node_modules/bootstrap/scss/mixins';
@import 'custom/mixins';
// Bootstrap components
@import '~node_modules/bootstrap/scss/root';
@import '~node_modules/bootstrap/scss/reboot';
@import '~node_modules/bootstrap/scss/type';
@import '~node_modules/bootstrap/scss/images';
@import '~node_modules/bootstrap/scss/code';
@import '~node_modules/bootstrap/scss/grid';
@import '~node_modules/bootstrap/scss/tables';
@import '~node_modules/bootstrap/scss/forms';
@import '~node_modules/bootstrap/scss/buttons';
@import '~node_modules/bootstrap/scss/transitions';
@import '~node_modules/bootstrap/scss/dropdown';
@import '~node_modules/bootstrap/scss/button-group';
@import '~node_modules/bootstrap/scss/input-group';
@import '~node_modules/bootstrap/scss/custom-forms';
@import '~node_modules/bootstrap/scss/nav';
@import '~node_modules/bootstrap/scss/navbar';
@import '~node_modules/bootstrap/scss/card';
@import '~node_modules/bootstrap/scss/breadcrumb';
@import '~node_modules/bootstrap/scss/pagination';
@import '~node_modules/bootstrap/scss/badge';
@import '~node_modules/bootstrap/scss/jumbotron';
@import '~node_modules/bootstrap/scss/alert';
@import '~node_modules/bootstrap/scss/progress';
@import '~node_modules/bootstrap/scss/media';
@import '~node_modules/bootstrap/scss/list-group';
@import '~node_modules/bootstrap/scss/close';
@import '~node_modules/bootstrap/scss/modal';
@import '~node_modules/bootstrap/scss/tooltip';
@import '~node_modules/bootstrap/scss/popover';
@import '~node_modules/bootstrap/scss/carousel';
@import '~node_modules/bootstrap/scss/utilities';
@import '~node_modules/bootstrap/scss/print';
// Custom components
@import 'custom/reboot';
@import 'custom/global';
@import 'custom/utilities';
@import 'custom/accordion';
@import 'custom/alerts';
@import 'custom/avatars';
@import 'custom/badge';
@import 'custom/buttons';
@import 'custom/card';
@import 'custom/carousel';
@import 'custom/close';
@import 'custom/custom-forms';
@import 'custom/dropdown';
@import 'custom/footer';
@import 'custom/forms';
@import 'custom/grid';
@import 'custom/icons';
@import 'custom/input-group';
@import 'custom/list-group';
@import 'custom/modal';
@import 'custom/nav';
@import 'custom/navbar';
@import 'custom/pagination';
@import 'custom/popover';
@import 'custom/progress';
@import 'custom/section';
@import 'custom/separator';
@import 'custom/type';
// Vendor
@import 'custom/vendor/bootstrap-datepicker';
@import 'custom/vendor/headroom';
@import 'custom/vendor/nouislider';
// Icons
@import 'icons';
// Web Fonts
@import 'webfonts';

.font- {
  &sm- {
    &10 {
      font-size: 10px;
    }
    &12 {
      font-size: 12px;
    }
  }
  &xl- {
    &50 {
      font-size: 50px;
    }
    &36 {
      font-size: 36px;
    }
  }
  &light {
    font-family: $font-light, sans-serif;
  }
}

.w {
  &-36 {
    width: 36%;
  }
  &-46 {
    width: 46%;
  }
  &-56 {
    width: 56%;
  }
  &-60 {
    width: 60%;
  }
  &-65 {
    width: 65%;
  }
  &-80 {
    width: 80%;
  }
  &-85 {
    width: 85%;
  }
  &-90 {
    width: 90%;
  }
  &-95 {
    width: 95%;
  }
}

.pl-2x {
  padding-left: 2rem;
}

.ant-select-selection__placeholder {
  font-family: inherit;
  color: inherit;
}

.ant-input,
.ant-input-number,
.ant-select-selection {
  height: 44px;
  background-color: var(--bg-input-text);
  border: 1px solid var(--color-input-border-hover);
  font-family: $font-regular, sans-serif;
  font-size: 14px;
  border-radius: 10px;
  &:focus {
    box-shadow: 0 0 0 2px rgba(188, 253, 236, 0.2);
  }
  &:focus,
  &:hover {
    border-color: var(--color-input-border-hover);
  }
  &::placeholder,
  & {
    color: $primary;
  }
}

.ant-select-selection__rendered {
  line-height: 44px;
}

.ant-form-item-label {
  padding: 0;
  line-height: normal;
  font-family: $font-bold, sans-serif;
  label,
  & {
    color: $primary;
  }
  label {
    &:after {
      display: none;
    }
  }
}

.btn:hover {
  transform: translateY(-1px);
}

.btn-green,
.btn-green:hover {
  background-color: $green;
  border-color: $green;
  color: white;
}

.btn-alt-black {
  background-color: #3c3c3c;
  border-color: #3c3c3c;
  color: white;
}

.ant-form-item {
  margin-bottom: 10px;
}

.ant-form {
  font-family: inherit;
}

.has-success.has-feedback .ant-form-item-children:after {
  color: $success;
}

.anticon {
  &.anticon-check-circle-fill,
  &.anticon-close-circle-fill {
    svg {
      width: 45%;
      height: auto;
    }
  }
}

.ant-select-selection__clear {
  background: rgba(101, 242, 218, 1);
  border-radius: 50%;
  color: white;
  .anticon {
    vertical-align: 1em;
  }
}
.bg-gray-black {
  background-color: #363636;
}
.text-green {
  color: $green;
}
