/* Form controls */

label {
  font-size: $font-size-sm;
}

.form-control {
  font-size: $input-btn-font-size;

  &:focus {
    &::placeholder {
      color: $input-focus-placeholder-color;
    }
  }
}

/* Textareas */

textarea[resize='none'] {
  resize: none !important;
}

textarea[resize='both'] {
  resize: both !important;
}

textarea[resize='vertical'] {
  resize: vertical !important;
}

textarea[resize='horizontal'] {
  resize: horizontal !important;
}

/* Alternative styles */

.form-control-muted {
  background-color: $input-muted-bg;
  border-color: $input-muted-bg;
  box-shadow: none;

  &:focus {
    background-color: $input-muted-bg;
    //box-shadow: none;
  }
}

.form-control-alternative {
  box-shadow: $input-alternative-box-shadow;
  border: 0;
  transition: box-shadow 0.15s ease;

  &:focus {
    box-shadow: $input-focus-alternative-box-shadow;
  }
}

// Sizes

.form-control-lg {
  font-size: $font-size-base;
}
