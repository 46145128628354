@import '../assets/theme';

body {
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
}

.btn-circle {
  border-radius: 50%;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title,
.subtitle {
  font-family: $font-bold, sans-serif;
}

.title-semi-bold {
  font-family: $font-semi-bold;
}

.title-extra-light {
  font-family: $font-extra-light, sans-serif;
}

.subtitle-light,
.title-light {
  font-family: $font-light, Serif;
  line-height: 1;
}

.text-black {
  color: $black;
}

.text-white {
  color: $white;
}

.text-gray-light {
  color: $light !important;
}

.nav-item {
  &.active {
    .nav-link {
      position: relative;
      &:after,
      &:before {
        position: absolute;
        top: 68%;
        left: 0;
        width: 100%;
        height: 2px;
        background: $info;
        content: '';
        transition: transform 0.3s;
        transform: scale(0.85);
      }
    }
  }
}

.footer {
  *,
  a,
  a:hover,
  .list-unstyled li a,
  .list-unstyled li a:hover {
    color: white;
    font-size: 14px;
  }
  a:hover {
    opacity: 0.8;
  }
  .footer-brand {
    width: 80px;
    margin-bottom: 10px;
  }
  .copyright {
    font-family: Arial, sans-serif;
    font-size: 10px;
    padding-left: 20px;
  }
}

.section-hero {
  min-height: auto;
  h1 {
    font-family: $font-black, sans-serif;
    font-size: 80px;
    @media (max-width: 1025px) {
      font-size: 50px;
    }
    color: white;
    line-height: 1;
  }
  .subtitle-light {
    font-size: 25px;
    font-family: $font-light, sans-serif;
  }
}

.account-type {
  .ant-form-item-label {
    line-height: 1;
    label {
      font-family: $font-bold, sans-serif;
      font-size: 14px;
      color: $primary;
      margin-bottom: 8px;
      &:after {
        display: none;
      }
    }
  }
  .ant-select-selection--single {
    height: 50px;
    border-radius: 10px;
    background-color: rgba(101, 242, 218, 0.5);
    border: 1px solid $info;
  }
  .ant-select-selection__rendered {
    line-height: 50px;
  }
  .ant-select-selection__placeholder,
  .ant-select {
    color: $primary;
    font-size: 14px;
    font-family: $font-semi-bold, sans-serif;
  }
}

.ant-select-dropdown {
  border-radius: 10px;
  border: 1px solid #c7c7c7;
}

.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #80808045;
}

.ant-select-dropdown-menu-item {
  display: flex;
  align-items: center;
}

.ant-select-dropdown-menu {
  .ant-select-dropdown-menu-item:hover {
    background-color: #8080801f;
  }
}

.ant-select-dropdown-menu-item,
.ant-select-dropdown-menu-item-active {
  font-family: $font-semi-bold, sans-serif;
  font-size: 14px;
  padding: 15px 12px;

  nz-embed-empty {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  &:first-child {
    border-radius: 9px 9px 0 0;
  }
  &:last-child {
    border-radius: 0 0 9px 9px;
  }
  &:hover {
    background-color: rgba(101, 242, 218, 0.5);
  }
}

.ant-select-dropdown-menu-item-selected,
.ant-select-dropdown-menu-item-selected:hover {
  background-color: rgba(199, 199, 199, 0.5);
}

.ant-select-dropdown-menu {
  border-radius: 9px;
}

.ant-select-open .ant-select-selection {
  box-shadow: 0 0 0 1px rgba(23, 198, 172, 0.2);
  border: 1px solid #bcfdec;
}

.ant-table-row {
  &.selected {
    background-color: #e0e0e0;
  }
}

.ant-table-tbody > tr.ant-table-row-hover > td,
.ant-table-tbody > tr:hover > td,
.ant-table-thead > tr.ant-table-row-hover > td,
.ant-table-thead > tr:hover > td {
  background-color: #e0e0e0;
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: #4d4d4d;
    border-color: #4d4d4d;
    &:after {
      left: 2.5px;
      top: 4px;
    }
  }
}

.ant-checkbox-inner {
  border: 2px solid #707070;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: #4d4d4d;
}

.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: #4d4d4d;
}

.custom-check {
  color: #6f6f6f;
  font-size: 20px;
  .ant-checkbox {
    margin-right: 6px;
    font-family: $font-regular, sans-serif;
  }
}

.custom-table {
  table {
    border-collapse: separate;
    border-spacing: 0 15px;
    .ant-checkbox-inner:after {
      display: block;
    }
    .ant-checkbox-inner:after,
    .ant-tree-checkbox-inner:after {
      width: 4.71px;
    }
  }
}

.brand-bg {
  width: 287px;
  background: white;
  border-radius: 0 0 12px 12px;
  align-items: flex-end;
  height: 100px;
  justify-content: center;
  padding-bottom: 8px;
  padding-top: 8px;
  display: grid;
  img {
    max-width: 237px;
  }
}

.medication-container {
  color: #4d4d4d;
  position: relative;

  &:hover {
    background-color: #f5f5f5;

    .buttons-edit-prescription button {
      opacity: 1;
    }

    .delete-medication-btn {
      opacity: 1;
    }
  }

  .title {
    font-family: $font-black, sans-serif;
    font-size: 18px;
    color: #4d4d4d;
  }

  .description {
    font-family: $font-light, sans-serif;
    font-size: 13px;
    color: #4d4d4d;
  }

  .activePrinciples {
    font-family: $font-light, sans-serif;
    font-size: 12px;
    color: #4d4d4d;
  }

  .buttons-edit-prescription {
    position: absolute;
    right: 0;
    top: 40%;
  }

  .buttons-edit-prescription button {
    @media (min-width: 1025px) {
      opacity: 0;
    }
    font-size: 18px;
    color: $danger;
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }

  .delete-medication-btn {
    opacity: 0;
    position: absolute;
    right: 0;
    top: 40%;
    font-size: 28px;
    color: $danger;
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }
}

.save-btn {
  box-shadow: none;
  border-radius: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.725rem;
}
.ant-dropdown {
  margin-top: 10px !important;
}

.photo {
  display: inline-block;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  vertical-align: middle;
  overflow: hidden;
}

// breakpoints
@media (max-width: 1025px) {
  .section {
    &.container {
      min-width: 320px;
      .w-46 {
        width: 100% !important;
        margin: 0 !important;
      }
    }
  }

  .ant-form-item label {
    font-size: 16px;
    margin-top: 13px;
    margin-left: 6px;
  }

  .medication-container .buttons-edit-prescription {
    position: relative;
    right: 0;
    top: 40%;
  }

  .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .ant-select-dropdown {
    width: 90%;
  }

  .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .ant-select-dropdown-menu-item {
    display: block;
    white-space: break-spaces;
    padding: 14px;
  }

  .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .ant-select-dropdown-menu-item > div {
    width: 180px;
    display: block;
  }
}

@media (max-width: 991px) {
  div#navbar-default {
    position: absolute;
    top: -106px;
    opacity: 1 !important;
    display: block;
    overflow: visible;

    .navbar-header {
      position: absolute;
      top: 115px;
      z-index: 9999;
      width: 220px;
      max-height: 60px;
      left: 8%;
      h1.navbar-text {
        font-size: 17px;
        line-height: 32px;
        div#navbar-default .navbar-header h1.navbar-text i {
          font-size: 15px;
        }
      }
    }
  }
  [title='Personalização de Tratamento'] div#navbar-default .navbar-header h1.navbar-text {
    font-size: 16px;
    line-height: 19px;
    i {
      font-size: 17px;
    }
  }

  ul.navbar-nav.ml-lg-auto.align-items-center {
    flex-direction: inherit;
    text-align: right;
    display: block;
    position: absolute;
    top: 110px;
    right: 0;
  }

  ul.navbar-nav.ml-lg-auto.align-items-center li.nav-item {
    display: inline-block;
    margin: 0 5px;
  }
}
