.modal-content {
  border: 0;
  align-items: center;
  @if $enable-rounded {
    border-radius: $modal-content-border-radius;
  } @else {
    border-radius: 0;
  }
  
  @media (max-width: 1025px) {
    > * {
      width: 100%;
    }
    .modal-header {
      width: auto!important;
    }
  }
}

@media (max-width: 1025px) {
  .modal-dialog {
    margin-top: 50%;
  }
}

.modal-fluid {
  .modal-dialog {
    margin-top: 0;
    margin-bottom: 0;
  }
  .modal-content {
    border-radius: 0;
  }
}

@each $color, $value in $theme-colors {
  .modal-#{$color} {
    @include modal-variant($value);
  }
}

