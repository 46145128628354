@font-face {
  font-family: 'problack';
  src: local('Source Sans Pro Black'), local('SourceSansPro-Black'),
    url('/assets/fonts/hinted-SourceSansPro-Black.woff2') format('woff2'),
    url('/assets/fonts/hinted-SourceSansPro-Black.woff') format('woff'),
    url('/assets/fonts/hinted-SourceSansPro-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'problack_italic';
  src: local('Source Sans Pro Black Italic'), local('SourceSansPro-BlackIt'),
    url('/assets/fonts/hinted-SourceSansPro-BlackIt.woff2') format('woff2'),
    url('/assets/fonts/hinted-SourceSansPro-BlackIt.woff') format('woff'),
    url('/assets/fonts/hinted-SourceSansPro-BlackIt.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'probold';
  src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
    url('/assets/fonts/hinted-SourceSansPro-Bold.woff2') format('woff2'),
    url('/assets/fonts/hinted-SourceSansPro-Bold.woff') format('woff'),
    url('/assets/fonts/hinted-SourceSansPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'probold_italic';
  src: local('Source Sans Pro Bold Italic'), local('SourceSansPro-BoldIt'),
    url('/assets/fonts/hinted-SourceSansPro-BoldIt.woff2') format('woff2'),
    url('/assets/fonts/hinted-SourceSansPro-BoldIt.woff') format('woff'),
    url('/assets/fonts/hinted-SourceSansPro-BoldIt.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'proextralight';
  src: local('Source Sans Pro ExtraLight'), local('SourceSansPro-ExtraLight'),
    url('/assets/fonts/hinted-SourceSansPro-ExtraLight.woff2') format('woff2'),
    url('/assets/fonts/hinted-SourceSansPro-ExtraLight.woff') format('woff'),
    url('/assets/fonts/hinted-SourceSansPro-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'proXLtIt';
  src: local('Source Sans Pro ExtraLight Italic'), local('SourceSansPro-ExtraLightIt'),
    url('/assets/fonts/hinted-SourceSansPro-ExtraLightIt.woff2') format('woff2'),
    url('/assets/fonts/hinted-SourceSansPro-ExtraLightIt.woff') format('woff'),
    url('/assets/fonts/hinted-SourceSansPro-ExtraLightIt.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'proitalic';
  src: local('Source Sans Pro Italic'), local('SourceSansPro-It'),
    url('/assets/fonts/hinted-SourceSansPro-It.woff2') format('woff2'),
    url('/assets/fonts/hinted-SourceSansPro-It.woff') format('woff'),
    url('/assets/fonts/hinted-SourceSansPro-It.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'prolight';
  src: local('Source Sans Pro Light'), local('SourceSansPro-Light'),
    url('/assets/fonts/hinted-SourceSansPro-Light.woff2') format('woff2'),
    url('/assets/fonts/hinted-SourceSansPro-Light.woff') format('woff'),
    url('/assets/fonts/hinted-SourceSansPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'prolight_italic';
  src: local('Source Sans Pro Light Italic'), local('SourceSansPro-LightIt'),
    url('/assets/fonts/SourceSansPro-LightIt.woff2') format('woff2'),
    url('/assets/fonts/SourceSansPro-LightIt.woff') format('woff'),
    url('/assets/fonts/SourceSansPro-LightIt.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'proregular';
  src: local('Source Sans Pro'), local('SourceSansPro-Regular'),
    url('/assets/fonts/hinted-SourceSansPro-Regular.woff2') format('woff2'),
    url('/assets/fonts/hinted-SourceSansPro-Regular.woff') format('woff'),
    url('/assets/fonts/hinted-SourceSansPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'prosemibold';
  src: local('Source Sans Pro Semibold'), local('SourceSansPro-Semibold'),
    url('/assets/fonts/hinted-SourceSansPro-Semibold.woff2') format('woff2'),
    url('/assets/fonts/hinted-SourceSansPro-Semibold.woff') format('woff'),
    url('/assets/fonts/hinted-SourceSansPro-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'proSBdIt';
  src: local('Source Sans Pro Semibold Italic'), local('SourceSansPro-SemiboldIt'),
    url('/assets/fonts/hinted-SourceSansPro-SemiboldIt.woff2') format('woff2'),
    url('/assets/fonts/hinted-SourceSansPro-SemiboldIt.woff') format('woff'),
    url('/assets/fonts/hinted-SourceSansPro-SemiboldIt.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "roboto";
  src: url('/assets/fonts/Roboto-Regular.ttf') format("truetype");
}
