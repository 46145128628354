// Image

.img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

// Clearfix

.floatfix {
  &:before,
  &:after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
}

// Overflows

.overflow-visible {
  overflow: visible !important;
}
.overflow-hidden {
  overflow: hidden !important;
}

// Opacity classes

.opacity-1 {
  opacity: 0.1 !important;
}
.opacity-2 {
  opacity: 0.2 !important;
}
.opacity-3 {
  opacity: 0.3 !important;
}
.opacity-4 {
  opacity: 0.4 !important;
}
.opacity-5 {
  opacity: 0.5 !important;
}
.opacity-6 {
  opacity: 0.6 !important;
}
.opacity-7 {
  opacity: 0.7 !important;
}
.opacity-8 {
  opacity: 0.8 !important;
}
.opacity-8 {
  opacity: 0.9 !important;
}
.opacity-10 {
  opacity: 1 !important;
}

._p-20 {
  padding: 20px!important;
}

._mt-20 {
  margin-top: 20px!important;
}

._mr-20 {
  margin-right: 20px!important;
}

._m-0 {
  margin: 0 !important;
}

._p-0 {
  padding: 0 !important;
}

._mb-10 {
  margin-bottom: 10px!important;
}
._mb-20 {
  margin-bottom: 20px!important;
}

._left {
  float: left!important;
}

._right {
  float: right!important;
}

._width-100-percent {
  width: 100%!important;
}

._height-100-percent {
  height: 100%!important;
}

._margin-auto {
  margin: auto;
}

._zindex-1 {
  z-index: 1!important;
}

._zindex-2 {
  z-index: 2!important;
}

._zindex-3 {
  z-index: 3!important;
}

._zindex-4 {
  z-index: 4!important;
}

._fixed {
  position: fixed!important;
}

._relative {
  position: relative!important;
}

._absolute {
  position: absolute!important;
}

._top-0 {
  top: 0!important;
}

._bottom-0 {
  bottom: 0!important;
}

._text-left {
  text-align: left!important;
}

._text-right {
  text-align: right!important;
}

._text-center {
  text-align: center!important;
}

._inline {
  display: inline!important;
}

._block {
  display: block!important;
}

._inline-block {
  display: inline-block!important;
}

._flex {
  display: flex;
}

._flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

._pointer {
  cursor: pointer!important;
}

._cursor-auto {
  cursor: auto!important;
}

._hidden {
  display: none!important;
}
._visible {
    overflow: visible!important;
}

._nowrap {
  white-space: nowrap!important;
}
